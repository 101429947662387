import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import moment from "moment";
import Environment from "../environment";
// @ts-ignore
import {v4 as uuidv4} from 'uuid';

const createAxiosService = (url: string) => {
    const axiosInstance = axios.create({
        baseURL: url,
    });
    axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
        // @ts-ignore
        const user: any = window?.user;
        if (user?.accessToken) {
            // @ts-ignore
            request.headers['Authorization'] = `Bearer ${user?.accessToken}`;
        }
        return request;
    });

    axiosInstance.interceptors.response.use(async (response: AxiosResponse) => {
        if (response.status === 401) {
            // Logout
        }
        return response;
    }, async (error) => {
        if (error.response) {
            if (error.response?.status === 401) {
            }
            return {
                status: error.response?.status || 400,
                data: error.response?.data,
            };
        }

        return Promise.reject(error);
    });
    return axiosInstance;
}

const appService = createAxiosService(Environment.appServiceUrl);

export function formatValues(values: any) {
    const result: any = {};
    if (values) {
        for (const valuesKey in values) {
            result[valuesKey] = values[valuesKey];
            if (valuesKey && (typeof values[valuesKey]) === 'string') {
                result[valuesKey] = (values[valuesKey] || "").toString().trim();
            }

        }
    }
    return result;
}

export const duration = (time: number, unit: any) => {
    const item = moment.duration(time, unit);
    return `${item.hours()}h ${item.minutes()}m `
}


export function round(value: any, exp: number) {
    if (typeof exp === 'undefined' || +exp === 0)
        return Math.round(value);

    value = +value;
    exp = +exp;

    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
        return NaN;

    // Shift
    value = value.toString().split('e');
    value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));

    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
}

export const sleep = async (timer: number) => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(true);
        }, timer)
    })
}

export const validateMessages = {
    required: "${label} is required!",
};

export const getTextAddress = (record: any, expand: boolean = true) => {
    return (record?.location || record?.address) && expand ? (record?.location || record?.address) : `${record?.district || ""}${record?.district ? "," : ""} ${record?.province || ""}${record?.province ? "," : ""} ${record?.country || ""}`
}

export function dateFormat(date: any) {
    return moment(date).format('HH:mm:ss DD/MM/YYYY');
}

export function getImageUrl(record: any, param: string, backupImage: string) {
    if (record?.state?.toLowerCase() === 'completed') {
        return record?.state?.toLowerCase() === 'completed' && record[param] ? record[param]?.thumbnail || record[param]?.normal || record[param]?.origin : backupImage;
    }
    return record[param]?.origin || backupImage || "";
}

export function validatePassword() {
    return {
        validator: async (rule: any, value: any, callback: any) => {
            if (value) {
                const password = value;
                const passwordChecker = () => {
                    return {
                        number: !password || !password.replace(/[^0-9]/g, '')?.toString() || (password === password.replace(/[^0-9]/g, '')?.toString()),
                        mixed: !(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/).test(password),
                        case: (password.toUpperCase() === password) || (password.toLowerCase() === password),
                        minimum: password.length < 8
                    }
                }
                if (passwordChecker().minimum) {
                    throw new Error('Password minimum 8 characters, at least one uppercase letter, one lowercase letter and one number');
                }
                if (passwordChecker().number) {
                    throw new Error('Password minimum 8 characters, at least one uppercase letter, one lowercase letter and one number');
                }
                if (passwordChecker().mixed) {
                    throw new Error('Password minimum 8 characters, at least one uppercase letter, one lowercase letter and one number');
                }
                if (passwordChecker().case) {
                    throw new Error('Password minimum 8 characters, at least one uppercase letter, one lowercase letter and one number');
                }
            }
        }
    }
}

export const getCurrentUser = async () => {
    const currentUser = localStorage.getItem('USER_V2');
    return currentUser ? JSON.parse(currentUser) : null;
}

export const postLogin = async (user: any) => {
    const result = await appService.post("/login", user);
    return result;
};

export const postSentOtp = async (user: any) => {
    return null;
};

export const postChangePassword = async (user: any, session: any) => {

    return null;
};

export const postResetPassword = async (user: any, session: any) => {
    return null;
};

export const postUploadText = async (file: any, folder: string = "temp") => {
    const result = await appService.get('/upload-url', {
        params: {
            folder: folder,
            extension: 'json'
        }
    });

    if (result.status === 200 && result?.data?.data) {
        return axios.put(result.data.data, file, {
            headers: {
                "Content-Type": 'application/json',
            }
        }).then(value => {
            return {
                status: value.status,
                data: result.data.data.toString().split('?')[0]
            };
        })
    }
}

export const postUpload = async (file: any, direct: boolean = false, folder: string = "temp") => {
    if (direct) {
        const formdata = new FormData();
        formdata.append("", file);
        return appService.post("/upload", formdata, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then(result => {
            if (result.status === 200) {
                return {
                    status: 200,
                    data: (result?.data?.data[0] || "")
                };
            }
            return result;
        });
    } else {
        const fs = await new Promise(resolve => {
            let reader = new FileReader()
            reader.onload = (e: any) => {
                resolve(e.target.result);
            }
            reader.readAsArrayBuffer(file);
        });
        const result = await appService.get('/upload-url', {
            params: {
                folder: folder,
                extension: file.name.toString().split('.').pop()
            }
        });

        if (result.status === 200 && result?.data?.data) {
            return axios.put(result.data.data, fs, {
                headers: {
                    "Content-Type": file.type,
                }
            }).then(value => {
                return {
                    status: value.status,
                    data: result.data.data.toString().split('?')[0]
                };
            })
        }
    }
}

const getUploadUrls = async (files: Array<File>) => {
    const result = await appService.post('/upload-url', files.map(file => {
        return {
            name: file.name,
            type: file.type
        }
    }));
    if (result.status === 200) {
        return result?.data?.data || []
    }
    return [];
}

const getFileBuffer = async (file: File) => {
    return await (new Promise(resolve => {
        let reader = new FileReader()
        reader.onload = (e: any) => {
            resolve(e.target.result);
        }
        reader.readAsArrayBuffer(file);
    }));
}

const putS3File = async (file: File, uri: string, fileUrl: string) => {
    const fs = await getFileBuffer(file);
    if (fs) {
        return axios.put(uri, fs, {
            headers: {
                "Content-Type": file.type,
            }
        }).then(value => {
            return {
                status: value.status,
                data: fileUrl
            };
        }).catch(reason => {
            return {
                status: 500,
                data: null
            };
        })
    }
    return null;
}

export const postUploads = async (files: Array<File>) => {
    const uploadUrls = await getUploadUrls(files);
    const result = [];
    if (uploadUrls.length === files.length) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileUrl = uploadUrls[i]?.url;
            const uploadUrl = uploadUrls[i]?.uploadUrl;
            const promise = putS3File(file, uploadUrl, fileUrl);
            result.push(promise);
        }
    }
    return result;
}

export const getAdminProfile = () => {
    return appService.get('/admin/profile');
}

export const getAdmin = (id: string) => {
    return appService.get(`/admin/${id}`);
}
export const listAdmin = (params: any) => {
    return appService.get(`/admin`, {params: params});
}
export const deleteAdmin = (id: string) => {
    return appService.delete(`/admin/${id}`);
}
export const putAdmin = (id: string, data: any) => {
    return appService.put(`/admin/${id}`, data);
}
export const postAdmin = (data: any) => {
    return appService.post(`/admin`, data);
}

export const getUser = (id: string) => {
    return appService.get(`/users/${id}`);
}
export const listUser = (params: any) => {
    return appService.get(`/users`, {params: params});
}
export const listUserByIds = (ids: any) => {
    return appService.get(`/users/list-by-ids`, {
        params: {
            ids: (ids || []).join(',')
        }
    });
}
export const deleteUser = (id: string) => {
    return appService.delete(`/users/${id}`);
}
export const putUser = (id: string, data: any) => {
    return appService.put(`/users/${id}`, data);
}
export const postUser = (data: any) => {
    return appService.post(`/users`, data);
}

export const getAttribute = (id: string) => {
    return appService.get(`/attribute/${id}`);
}
export const listAttribute = (params: any) => {
    return appService.get(`/attribute`, {params: params});
}
export const deleteAttribute = (id: string) => {
    return appService.delete(`/attribute/${id}`);
}
export const putAttribute = (id: string, data: any) => {
    return appService.put(`/attribute/${id}`, data);
}
export const postAttribute = (data: any) => {
    return appService.post(`/attribute`, data);
}

export const getLocation = (country: string) => {
    return axios.get(``);
}

export const truncate = (str: string, n: number) => {
    return (str?.length > n) ? str.slice(0, n - 1) + '...' : (str || "");
};

export const getJsonContent = (uri: string) => {
    return axios.get(uri);
}

export const geoJsonConverter = (values: any, type: 'in' | 'out') => {
    if (type === 'out') {
        return {
            "coordinates": values.coordinates || [],
            "planned_elevation": values.planned_elevation || [],
            "guides": values.guides || [],
            "splits": values?.splits?.length >= 0 ? values?.splits : undefined,
        }
    } else {
        return {
            "coordinates": values?.coordinates || [],
            "planned_elevation": values?.planned_elevation || [],
            "guides": values.guides || [],
            "splits": values?.splits?.length >= 0 ? values?.splits : undefined,
        }
    }
}

export const navDetail = (id: string, navigate: any, type: string) => {
    // let route = `/user/customers/update/${id}`;
    // if (type === 'admin') {
    //     route = `/user/admins/update/${id}`;
    // }
    // if (type === 'attribute') {
    //     route = `/trail/attributes/update/${id}`;
    // }
    // if (type === 'trail') {
    //     route = `/trail/trails/update/${id}`;
    // }
    // navigate(route);
}

export const getTrail = (id: string) => {
    return appService.get(`/trail/${id}`);
}
export const getTrailAttribute = (id: string) => {
    return appService.get(`/trail-attribute/${id}`);
}
export const getTrailMap = (id: string) => {
    return appService.get(`/trail-map/${id}`);
}
export const getTrailOwner = (id: string) => {
    return appService.get(`/trail-owner/${id}`);
}
export const listTrail = (params: any) => {
    return appService.get(`/trail`, {params: params});
}
export const listTrailByIds = (ids: any) => {
    return appService.get(`/trail/list-by-ids`, {
        params: {
            ids: (ids || []).join(',')
        }
    });
}
export const deleteTrail = (id: string) => {
    return appService.delete(`/trail/${id}`);
}
export const putTrail = (id: string, data: any) => {
    return appService.put(`/trail/${id}`, data);
}
export const postTrail = (data: any) => {
    return appService.post(`/trail`, data);
}
export const putTrailAttribute = (id: string, data: any) => {
    return appService.put(`/trail-attribute/${id}`, {...data, trail_id: id});
}
export const postTrailOwner = (id: string, data: any) => {
    return appService.post(`/trail-owner`, {trail_id: id});
}
export const putTrailMap = (id: string, data: any) => {
    return appService.put(`/trail-map/${id}`, {...data});
}

export const getAddress = (location: any) => {
    const address = window.encodeURIComponent(`${location?.district}, ${location?.province}, ${location?.country}`);
    return axios.get(``);
}


export const getReview = (id: string) => {
    return appService.get(`/reviews/${id}`);
}
export const listReview = (params: any) => {
    return appService.get(`/reviews`, {params: params});
}
export const deleteReview = (id: string) => {
    return appService.delete(`/reviews/${id}`);
}
export const putReview = (id: string, data: any) => {
    return appService.put(`/reviews/${id}`, data);
}
export const postReview = (data: any) => {
    return appService.post(`/reviews`, data);
}

export const getPhoto = (id: string) => {
    return appService.get(`/photos/${id}`);
}
export const listPhoto = (params: any) => {
    return appService.get(`/photos`, {params: params});
}
export const deletePhoto = (id: string) => {
    return appService.delete(`/photos/${id}`);
}
export const putPhoto = (id: string, data: any) => {
    return appService.put(`/photos/${id}`, data);
}
export const postPhoto = (data: any) => {
    return appService.post(`/photos`, data);
}

export const getActivity = (id: string) => {
    return appService.get(`/activity/${id}`);
}
export const listActivity = (params: any) => {
    if (!params.status) {
        params.status = 'Active,Inactive,Verified'
    }
    return appService.get(`/activity`, {params: params});
}
export const deleteActivity = (id: string) => {
    return appService.delete(`/activity/${id}`);
}
export const putActivity = (id: string, data: any) => {
    return appService.put(`/activity/${id}`, data);
}
export const postActivity = (data: any) => {
    return appService.post(`/activity`, data);
}


export const getResource = (id: string) => {
    return appService.get(`/resource/${id}`);
}
export const listResource = (params: any) => {
    return appService.get(`/resource`, {params: params});
}
export const deleteResource = (id: string) => {
    return appService.delete(`/resource/${id}`);
}
export const putResource = (id: string, data: any) => {
    return appService.put(`/resource/${id}`, data);
}
export const postResource = (data: any) => {
    return appService.post(`/resource`, data);
}

export const getResponse = (id: string) => {
    return appService.get(`/response/${id}`);
}
export const listResponse = (params: any) => {
    return appService.get(`/response`, {params: params});
}
export const deleteResponse = (id: string) => {
    return appService.delete(`/response/${id}`);
}
export const putResponse = (id: string, data: any) => {
    return appService.put(`/response/${id}`, data);
}
export const postResponse = (data: any) => {
    return appService.post(`/response`, data);
}