import React, {Suspense, useEffect} from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";
import {useApp} from "../contexts/App";
import {Layout} from "../components/Layout";
import Loading from "../components/Loading";

const Auth = React.lazy(() => import("../pages/auth"));
const Home = React.lazy(() => import("../pages/home"));

const AdminList = React.lazy(() => import("../pages/admin/list"));
const AdminEdit = React.lazy(() => import("../pages/admin/edit"));

const UserList = React.lazy(() => import("../pages/user/list"));
const UserEdit = React.lazy(() => import("../pages/user/edit"));

const ResourceEdit = React.lazy(() => import("../pages/resource/edit"));
const ResourceList = React.lazy(() => import("../pages/resource/list"));

const ResponseEdit = React.lazy(() => import("../pages/response/edit"));
const ResponseList = React.lazy(() => import("../pages/response/list"));

let PREV_URL = window.location.href.replace(origin, "");

export const routers = [
    {
        path: "/",
        element: Home,
        meta: {
            title: "Home",
            breadcrumb: [{path: null, breadcrumbName: "Home"}],
        },
    },
    {
        path: "/resources",
        element: () => <ResourceList/>,
        meta: {
            title: "Quản lý liên kết",
            breadcrumb: [{path: null, breadcrumbName: "Quản lý liên kết"}],
        },
    },
    {
        path: "/resources/create",
        element: () => <ResourceEdit/>,
        meta: {
            title: "Tạo liên kết",
            breadcrumb: [{path: null, breadcrumbName: "Quản lý liên kết"}],
        },
    },
    {
        path: "/resources/update/:id",
        element: () => <ResourceEdit/>,
        meta: {
            title: "Sửa liên kết",
            breadcrumb: [{path: null, breadcrumbName: "Quản lý liên kết"}],
        },
    },


    {
        path: "/responses",
        element: () => <ResponseList/>,
        meta: {
            title: "Quản lý dữ liệu",
            breadcrumb: [{path: null, breadcrumbName: "Quản lý dữ liệu"}],
        },
    },
    {
        path: "/responses/create",
        element: () => <ResponseEdit/>,
        meta: {
            title: "Tạo dữ liệu",
            breadcrumb: [{path: null, breadcrumbName: "Quản lý dữ liệu"}],
        },
    },
    {
        path: "/responses/update/:id",
        element: () => <ResponseEdit/>,
        meta: {
            title: "Sửa dữ liệu",
            breadcrumb: [{path: null, breadcrumbName: "Quản lý dữ liệu"}],
        },
    },
];

function AppRouter() {
    const {user} = useApp();

    return (
        <Suspense fallback={null}>
            {!!user ? (
                <Router>
                    <Layout>
                        <Routes>
                            <Route
                                path="/auth/*"
                                element={
                                    <Navigate replace to="/trail/trails"/>
                                }
                            />
                            <Route
                                path="/"
                                element={
                                    <Navigate replace to="/trail/trails"/>
                                }
                            />
                            {routers.map((value, index) => {
                                const Element:any = value.element;
                                return (
                                    <Route
                                        key={index.toString()}
                                        path={value.path}
                                        element={
                                            <React.Suspense fallback={<Loading/>}>
                                                <Element/>
                                            </React.Suspense>
                                        }
                                    />
                                );
                            })}
                        </Routes>
                    </Layout>
                </Router>
            ) : (
                <Router>
                    <Routes>
                        <Route
                            path="/*"
                            element={<Navigate replace to="/auth/login"/>}
                        />
                        <Route
                            path="/auth"
                            element={<Navigate replace to="/auth/login"/>}
                        />
                        <Route
                            path="/auth/login"
                            element={
                                <React.Suspense fallback={<Loading/>}>
                                    <Auth
                                        redirectUrl={
                                            PREV_URL.includes("")
                                                ? PREV_URL
                                                : null
                                        }
                                    />
                                </React.Suspense>
                            }
                        />
                    </Routes>
                </Router>
            )}
        </Suspense>
    );
}

export default AppRouter;
