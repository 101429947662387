import React, {useEffect, useState} from "react";
import {getCurrentUser} from "../services/app.service";
import Loading from "../components/Loading";

const App = React.createContext<{
    user?: any,
    setUser?: any,
    logout?: () => void,
    setLoading?: any,
}>({});

export const AppProvider = ({children}: any) => {
    const [user, setUser] = useState<any>(null);
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const run = async () => {
            const currentUser = await getCurrentUser().catch(reason => null);
            setUser(currentUser);
            setLoading(false);
        }
        run().finally(() => {
            setReady(true);
        });
    }, []);

    useEffect(() => {
        localStorage.setItem('USER_V2', JSON.stringify(user));
    }, [user])

    const logout = () => {
        setUser(null);
    }

    return (
        <App.Provider
            value={{
                user,
                setUser,
                logout,
                setLoading
            }}>
            {ready ? children : null}
            {loading ? <Loading/> : null}
        </App.Provider>
    )
}

export const useApp = () => React.useContext(App);